window.activeSidebarLink = function (name) {
    $('.sidebar .collapse').collapse('hide');
    $('.sidebar [data-name], .sidebar .nav-item').removeClass('active');

    let sidebar = $('.sidebar');
    let elem = $('.sidebar [data-name=' + name + ']');
    if (elem.length) {
        if (elem.is('li') && elem.hasClass('nav-item')) {
            elem.addClass('active');
        } else {
            if (elem.is('a') && elem.hasClass('collapse-item')) {
                elem.addClass('active');
                let nav_item = elem.parents('li.nav-item');
                if (nav_item.length) {
                    nav_item.addClass('active');
                    if (!sidebar.hasClass('toggled') && $(window).width() >= 768) {
                        let collapse_block = nav_item.find('.collapse');
                        if (collapse_block) {
                            collapse_block.collapse('show');
                        }
                    }
                }
            }
        }
    }
};


(function($) {
    "use strict"; // Start of use strict
    $('.sidebar .collapse').collapse('hide');

    let sidebar_toggled = $.cookie('sidebar_toggled');
    if (sidebar_toggled === undefined || sidebar_toggled === null) {
        if ($(window).width() >= 768)  {
            sidebarOpen();
        }
    }

    sidebar_toggled = $.cookie('sidebar_toggled');
    if ($(window).width() >= 768)  {
        if (sidebar_toggled) sidebarClose();
    }

    if (typeof sidebar_active_link !== "undefined" && sidebar_active_link) {
        activeSidebarLink(sidebar_active_link);
    }

    function sidebarToggle() {
        $("body").toggleClass("sidebar-toggled");
        let sidebar = $(".sidebar");
        sidebar.toggleClass("toggled");
        if (sidebar.hasClass("toggled")) {
            $('.sidebar .collapse').collapse('hide');
            $.cookie('sidebar_toggled', 1, {path: '/'})
        } else {
            $.cookie('sidebar_toggled', 0, {path: '/'})
        }
    }

    function sidebarOpen() {
        $("body").removeClass("sidebar-toggled");
        let sidebar = $(".sidebar");
        sidebar.removeClass("toggled");
        $.cookie('sidebar_toggled', 0, {path: '/'})
    }
    function sidebarClose() {
        $("body").addClass("sidebar-toggled");
        let sidebar = $(".sidebar");
        sidebar.addClass("toggled");
        $('.sidebar .collapse').collapse('hide');
        $.cookie('sidebar_toggled', 1, {path: '/'})
    }

    // Toggle the side navigation
    $("#sidebarToggle, #sidebarToggleTop").on('click', function(e) {
        sidebarToggle();
    });

    // Close any open menu accordions when window is resized below 768px
    $(window).resize(function() {
        if ($(window).width() < 768) {
            $('.sidebar .collapse').collapse('hide');
        };
    });

    // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
    $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function(e) {
        if ($(window).width() > 768) {
            var e0 = e.originalEvent,
                delta = e0.wheelDelta || -e0.detail;
            this.scrollTop += (delta < 0 ? 1 : -1) * 30;
            e.preventDefault();
        }
    });

    // Scroll to top button appear
    $(document).on('scroll', function() {
        var scrollDistance = $(this).scrollTop();
        if (scrollDistance > 100) {
            $('.scroll-to-top').fadeIn();
        } else {
            $('.scroll-to-top').fadeOut();
        }
    });

    // Smooth scrolling using jQuery easing
    $(document).on('click', 'a.scroll-to-top', function(e) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: ($($anchor.attr('href')).offset().top)
        }, 1000, 'easeInOutExpo');
        e.preventDefault();
    });

    $('.dropdown-toggle').dropdown();
    $('input.js-mask-phone').inputmask('+9{10,12}', {
        "clearIncomplete": true,
        "showMaskOnHover": false,
        definitions: {
            'c': {
                validator: "[1234]"
            },
            'C': {
                validator: "[34579]"
            }
        }
    });
    $('input.js-mask-time').inputmask('99:99:99', {placeholder: "--:--:--"});

})(jQuery); // End of use strict
